import React from 'react'

import './contact.css'
import Layout from '../../AllComponents/Layout'

const Contact = () => {
    return (
        <>
            <Layout>
                <section className='newsbanner-sec' 
                style={{backgroundImage:`url(${process.env.REACT_APP_IMAGEBASE_URL}/newsbannerbg.jpg)`}}>
                    <div className="container ">
                        <div className="row ">
                            <div className="col-12">
                                <div class="headingRout">Home / <span> Contact </span></div>
                                <h1> Get in Touch </h1>
                                <p>For any inquiries, please call or email us. Alternatively you can fill in the following contact
                                    form.</p>
                            </div>
                        </div>
                    </div>
                </section>


                <section className='message-sec'>
                    <div className="container ">
                        <div className="row ">
                            <div className="col-12">
                                <div className="message-box">
                                    <h2>Send Us a Message</h2>

                                    <div className=''>
                                        <nav>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Ask Query</button>
                                                <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Join the Team</button>
                                            </div>
                                        </nav>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
                                                <form>
                                                    <div className='row'>
                                                        <div className='col-md-6 mb-4'>
                                                            <label>Full Name</label>
                                                            <input type='text' className='form-control' placeholder='Enter your Full Name' />
                                                        </div>

                                                        <div className='col-md-6 mb-4'>
                                                            <label>Contact</label>
                                                            <input type='text' className='form-control' placeholder='Enter your Contact Number' />
                                                        </div>

                                                        <div className='col-md-6 mb-4'>
                                                            <label>Email</label>
                                                            <input type='email' className='form-control' placeholder='Enter your Email Address' />
                                                        </div>

                                                        <div className='col-md-6 mb-4'>
                                                            <label>Company </label>
                                                            <input type='text' className='form-control' placeholder='Enter your Company Name' />
                                                        </div>
                                                        <div className='col-md-6 mb-4'>
                                                            <label>Designation</label>
                                                            <input type='text' className='form-control' placeholder='Enter your Designation' />
                                                        </div>
                                                        <div className='col-md-6 mb-4'>
                                                            <label>Business Option </label>
                                                            <select class="form-select" aria-label=".form-select-lg example">
                                                                <option selected>Business Option</option>
                                                                <option value="1">Business Option</option>
                                                                <option value="2">Business Option</option>
                                                                <option value="3">Business Option</option>
                                                            </select>

                                                        </div>

                                                        <div className='col-md-12 mb-4'>
                                                            <label> Subject  </label>
                                                            <input type='text' className='form-control' placeholder='Enter subject line' />
                                                        </div>

                                                        <div className='col-md-12 mb-4'>
                                                            <textarea className='form-control' rows='5' placeholder='Additional Comments '></textarea>
                                                        </div>

                                                        <div className='col-md-12 text-center'>
                                                            <button className='btn '> Submit </button>
                                                        </div>





                                                    </div>
                                                </form>

                                            </div>
                                            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
                                                <form>
                                                    <div className='row'>
                                                        <div className='col-md-6 mb-4'>
                                                            <label>Full Name</label>
                                                            <input type='text' className='form-control' placeholder='Enter your Full Name' />
                                                        </div>

                                                        <div className='col-md-6 mb-4'>
                                                            <label>Contact</label>
                                                            <input type='text' className='form-control' placeholder='Enter your Contact Number' />
                                                        </div>

                                                        <div className='col-md-6 mb-4'>
                                                            <label>Email</label>
                                                            <input type='email' className='form-control' placeholder='Enter your Email Address' />
                                                        </div>

                                                        <div className='col-md-6 mb-4'>
                                                            <label>Available Openings </label>
                                                            <select class="form-select" >
                                                                <option selected>Choose Opening</option>
                                                                <option value="1">Choose Opening</option>
                                                                <option value="2">Choose Opening</option>
                                                                <option value="3">Choose Opening</option>
                                                            </select>
                                                        </div>                                                       

                                                        <div className='col-md-6 mb-4'>
                                                            <label>Upload File (Max 20KB) </label>
                                                            {/* <input type='file' className='form-control' placeholder='Attach your resume' /> */}
                                                            
                                                            <div className='file-box' >
                                                                <div className='uploadinfo'>Attach your resume</div>
                                                                <input type='file'    className='fileclass' placeholder='Attach your resume' />
                                                            </div>

                                                        </div>
                                                        <div className='col-md-6 mb-4'>
                                                            <label>Current Company </label>
                                                            <input type='text' className='form-control' placeholder='Enter your Company Name' />
                                                        </div>
                                                        <div className='col-md-6 mb-4'>
                                                            <label>Current CTC</label>
                                                            <input type='text' className='form-control' placeholder='Eg: 8LPA' />
                                                        </div>                                                       

                                                        <div className='col-md-6 mb-4'>
                                                            <label> Expected CTC  </label>
                                                            <select class="form-select" >
                                                                <option selected>Eg: 10LPA</option>
                                                                <option value="1">Eg: 10LPA</option>
                                                                <option value="2">Eg: 12LPA</option>
                                                                <option value="3">Eg: 14LPA</option>
                                                            </select>                                                        
                                                        </div>
                                                        <div className='col-md-12 text-center'>
                                                            <button className='btn '> Submit </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="addres-box">
                                    <div className='leftpart'>
                                        <h2>Unit No. 405, 4th Floor, ‘E’ Wing, Corporate Avenue New AK Link Road Chakala, Andheri (East),
                                            Mumbai - 400059 Maharashtra, India </h2>
                                    </div>
                                    <div className='rightpart'>
                                        <p> <a href='tel:2269074500'> (+91) 22 6907 4500 </a> </p>
                                        <p> <a href='mailto:contact@greencellmobility.com'> contact@greencellmobility.com </a> </p>
                                        <p> <a href='mailto:care@greencellmobility.com'>Grievance Officer: care@greencellmobility.com</a> </p>
                                    </div>
                                </div>

                                <div className='map-sec'>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.930113332057!2d72.85423688419498!3d19.110721604747493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ceebf38ca25b%3A0x9e2ec0b15eb7f79e!2sGreencell%20Mobility%20Private%20Limited!5e0!3m2!1sen!2sin!4v1689682701818!5m2!1sen!2sin" className='w-100 d-block' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>



            </Layout>
        </>
    )
}

export default Contact
