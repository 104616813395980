import React, { useState } from "react";
import "./overView.css"
// import overviewimg from '../../../assets/images/aboutus.png'
// import overviewarrow from '../../../assets//images/arrow.svg'




const OverView = () => {

    const [ ReadMoreLess,setReadMoreLess]=useState(false)


    return (
        <>
            <section className="overview-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="overview-part">
                                <div className="left_part">
                                    <div className="accordion-part">
                                        <div class="accordion" id="accordionExample">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Overview
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>It’s time for clean, quiet transportation for all creating a sustainable green future. 
                                                            Communities are growing and evolving  Now more than ever, we need safer { !ReadMoreLess &&<span> ...</span> }
                                                           {ReadMoreLess && <span>
                                                                more reliable and cleaner options. Every day, GreenCell Mobility works
                                                                to meet those needs with the world’s best electric vehicle technology solutions.
                                                            </span>}
                                                        </p>
                                                        <a href="javascript:void(0);" onClick={(e)=>setReadMoreLess(o=>!o)}>{!ReadMoreLess?'Read More':"See Less"} 
                                                        {/* <img src={overviewarrow} alt="img"/>  */}

                                                        
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Vision
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>GreenCell Mobility is dedicated on building a platform to provide Electric Mobility-as-a-Service (eMaaS)</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Our Business
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>GreenCell wants to be the prime mover in building a sustainable growth momentum in the shared e-mobility space. Integrate and create powerful connected platforms/ecosystem across products and services segments in the value chain to create and offer a robust e-mobility infrastructure capability in the B2G, B2C and B2B domains.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item d-none">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        Our Business
                                                    </button>
                                                </h2>
                                                <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>This is the third item's accordion body.It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the though the transition does limit overflow. </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="accordion-item d-none">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                        Our Business
                                                    </button>
                                                </h2>
                                                <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>This is the third item's accordion body.It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the though the transition does limit overflow. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="right_part">
                                    {/* <img src={"overviewimg"} alt="Overview-img" className="w-100 rounded" /> */}
                                     <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/GCM_Overview_MG_9561_610%20X%20363%20px.jpg`} alt="Overview-img" className="w-100 rounded" />

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}



export default OverView